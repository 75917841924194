<template>
  <nav aria-label="Progress">
    <div class="mx-2 flex">
      <StepperStep
        :step="step"
        :status="
          stepIdx > currentStep
            ? 'PENDING'
            : stepIdx < currentStep
            ? 'COMPLETE'
            : 'CURRENT'
        "
        v-for="(step, stepIdx) in props.steps"
        :key="step.name"
        :last="stepIdx === props.steps.length - 1"
      />
    </div>

    <div class="mt-1 flex justify-between" v-if="!props.noTitles">
      <StepperStepName
        :step="step"
        :status="
          stepIdx > currentStep
            ? 'PENDING'
            : stepIdx < currentStep
            ? 'COMPLETE'
            : 'CURRENT'
        "
        v-for="(step, stepIdx) in props.steps"
        :key="step.name"
        :first="stepIdx === 0"
        :last="stepIdx === props.steps.length - 1"
      />
    </div>
  </nav>
  <div v-for="(step, stepIdx) in props.steps" :key="step.name">
    <div class="mt-5 py-3" v-if="stepIdx === currentStep">
      <slot :name="`content-step-${stepIdx + 1}`">
        <VForm class="bg-gray-200">
          <slot :name="body">
            {{ step.name }}
          </slot>
        </VForm>
      </slot>
    </div>
  </div>
  <div class="mt-5 justify-between sm:mt-4 sm:flex">
    <slot name="footer">
      <VButton
        label="Previous"
        class="w-28"
        @click="previous()"
        v-if="currentStep > 0"
      />
      <div v-else></div>
      <VButton
        label="Next"
        variant="primary"
        class="w-28"
        @click="next()"
        v-if="currentStep < steps.length - 1"
        :disabled="nextDisabled"
      />
      <VButton
        label="Finish"
        variant="primary"
        class="w-28"
        @click="submit()"
        v-else
        :disabled="submitDisabled"
      />
    </slot>
  </div>
</template>

<script setup>
import { ref } from "vue";
import StepperStep from "@/components/helpers/StepperStep.vue";
import StepperStepName from "@/components/helpers/StepperStepName.vue";

// eslint-disable-next-line sonarjs/no-duplicate-string
const emit = defineEmits(["submit", "update:step"]);
const props = defineProps({
  steps: {
    type: Array,
    required: true,
  },
  noTitles: {
    type: Boolean,
    default: false,
  },
  submitDisabled: {
    type: Boolean,
    default: false,
  },
  nextDisabled: {
    type: Boolean,
    default: false,
  },
});

const currentStep = ref(0);

const next = () => {
  if (currentStep.value < props.steps.length - 1) {
    currentStep.value++;
  }
  emit("update:step", currentStep.value);
};

const previous = () => {
  if (currentStep.value > 0) {
    currentStep.value--;
  }
  emit("update:step", currentStep.value);
};

const submit = () => {
  emit("submit");
};
</script>
